import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../context/DataContext";
import {
  Button,
  Collapse,
  Empty,
  Flex,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import { CollapseProps } from "antd/es/collapse/Collapse";
import {
  FileAddOutlined,
  ArrowLeftOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { CommentProps, TicketProps } from "../../../types/interfaces";
import {
  IssuesCloseOutlined,
  ClockCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Styles from "./TicketList.module.css";
import AuthContext from "../../../context/AuthContext";

const { Title, Text, Paragraph } = Typography;

export const TicketList = () => {
  const {
    ticketList,
    OnOpenCloseTicketModal,
    handleFetchAwsFile,
    OnOpenCloseCommentModal,
    handleEditTicket,
    isLoading,
  } = useContext(DataContext);
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState<CollapseProps["items"]>([]);

  useEffect(() => {
    if (ticketList.length > 0) getTicketList();
  }, [ticketList]);

  const getTicketList = () => {
    const newTicketList = ticketList.map((ticket) => {
      return {
        key: ticket?.id,
        label: (
          <span className={Styles.ticket_list__user}>
            <span>
              Ticket Id: <strong>{ticket?.id.substring(0, 5)}</strong>
            </span>
            <>{ticket?.subject}</> - <>{ticket.comment_list[0].user.name}</>
          </span>
        ),
        children: getComments(ticket?.comment_list, ticket?.solved),
        extra: genExtra(ticket),
      };
    });
    setData(newTicketList);
  };

  const createComment = (ticketId: string) => {
    localStorage.setItem("ticket", ticketId);
    OnOpenCloseCommentModal(true);
  };

  const getComments = (comments: CommentProps[], solved: boolean) => {
    const items = comments.map((comment) => {
      return {
        children: (
          <span className={Styles.ticket_list__comments__time}>
            <div>{dayjs(comment?.date).format("MM-DD-YYYY HH:mm:ss")}</div>
            <div>
              <strong>{comment.user?.name}:</strong>
            </div>
            <div>{comment.comment}</div>
            {comment.path && (
              <Button
                disabled={isLoading}
                type="link"
                icon={<PaperClipOutlined />}
                onClick={() => comment.path && handleFetchAwsFile(comment.path)}
              >
                {comment.path || "Download File"}
              </Button>
            )}
          </span>
        ),
        color: comment.is_admin ? "blue" : "green",
      };
    });
    return (
      <>
        <div className={Styles.ticket_list__comments}>
          {userData?.dashboard && (
            <>
              {solved ? (
                <Button
                  icon={<IssuesCloseOutlined />}
                  style={{ marginRight: "1rem" }}
                  onClick={() =>
                    handleEditTicket({
                      ticketId: comments[0].ticket_id,
                      updates: { solved: false },
                    })
                  }
                >
                  Reopen Ticket
                </Button>
              ) : (
                <Button
                  icon={<IssuesCloseOutlined />}
                  style={{ marginRight: "1rem" }}
                  onClick={() =>
                    handleEditTicket({
                      ticketId: comments[0].ticket_id,
                      updates: { solved: true, user_id: userData?.id },
                    })
                  }
                >
                  Mark as solved
                </Button>
              )}
            </>
          )}

          <Button
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => createComment(comments[0].ticket_id)}
            disabled={solved}
          >
            Add Comment
          </Button>
        </div>

        <Timeline items={items} />
      </>
    );
  };

  const genExtra = (ticket: TicketProps) => {
    return (
      <Flex gap={5}>
        <Typography>
          <Text strong className="text--white">
            {dayjs(ticket?.date).format("MM-DD-YYYY HH:mm:ss")}
          </Text>
        </Typography>
        {!ticket.solved ? (
          <ClockCircleOutlined className="icon--white" />
        ) : (
          <IssuesCloseOutlined className="icon--white" />
        )}
      </Flex>
    );
  };

  return (
    <>
      {ticketList.length > 0 ? (
        <>
          <div className={Styles.ticket_list__extra}>
            <Typography>
              <Tooltip title="Home" placement="right">
                <Button
                  style={{ marginBottom: "1rem" }}
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate("/support")}
                ></Button>
              </Tooltip>
              {!userData?.dashboard && (
                <>
                  <Title level={4}>
                    This is the list of your last 10 tickets:
                  </Title>
                  <Paragraph>
                    Please contact us if you need further information or help.
                  </Paragraph>
                </>
              )}
            </Typography>
            <Tooltip title="Create New Ticket">
              <Button
                type="primary"
                onClick={() => OnOpenCloseTicketModal(true)}
                icon={<PlusOutlined />}
              >
                Create a new Ticket
              </Button>
            </Tooltip>
          </div>
          <Collapse
            accordion={true}
            defaultActiveKey={ticketList[0].id}
            expandIconPosition={"start"}
            items={data}
            size="large"
            className={Styles.ticket_list__accordion}
          />
        </>
      ) : (
        <Empty description={<span>You have not created any tickets</span>}>
          <Button
            type="primary"
            disabled={isLoading}
            onClick={() => OnOpenCloseTicketModal(true)}
            icon={<PlusOutlined />}
          >
            Create Ticket Now
          </Button>
        </Empty>
      )}
    </>
  );
};
