import React from "react";
import { theme } from "antd";
import { Footer } from "antd/es/layout/layout";

export const AppFooter = () => {
  const {
    token: { colorFillSecondary },
  } = theme.useToken();
  return (
    <Footer
      style={{
        textAlign: "center",
        background: colorFillSecondary,
        // color: "#FFF",
      }}
    >
      ©Copyright{" "}
      <a href="https://quattroapps.com" target="_blank" rel="noreferrer">
        Quattro Software
      </a>
      , {new Date().getFullYear()}
    </Footer>
  );
};
