import { Card, Grid, List } from "antd";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import {
  UserOutlined,
  OrderedListOutlined,
  FileAddOutlined,
  ExportOutlined,
  BookOutlined,
} from "@ant-design/icons";
import Styles from "./SupportComponents.module.css";

export const SupportCardList = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const { OnOpenCloseTicketModal, goToClientsApp, fetchDashboardProduct } =
    useContext(DataContext);

  const admOptions = [
    {
      title: "Dashboard",
      onClick: () => fetchDashboardProduct(),
      icon: <UserOutlined className="icon--white" />,
    },
  ];

  const options = [
    {
      title: "Profile",
      onClick: () => navigate(`/client/${userData.id}`),
      icon: <UserOutlined className="icon--white" />,
    },
    {
      title: "App User Guide",
      onClick: () => navigate(`/guide/${userData.id}`),
      icon: <BookOutlined className="icon--white" />,
    },
    {
      title: "Support Tickets",
      onClick: () => navigate(`/support/ticket/${userData.id}`),
      icon: <OrderedListOutlined className="icon--white" />,
    },

    {
      title: "New Support Ticket",
      onClick: () => OnOpenCloseTicketModal(true),
      icon: <FileAddOutlined className="icon--white" />,
    },

    {
      title: "Go to your app",
      onClick: goToClientsApp,
      icon: <ExportOutlined className="icon--white" />,
    },
    {
      title: "Visit our website",
      onClick: () => window.open("https://quattroapps.com"),
      icon: <ExportOutlined className="icon--white" />,
    },
  ];
  return (
    <>
      {userData.dashboard && (
        <List
          size="large"
          grid={{ gutter: 20, column: 1 }}
          dataSource={admOptions}
          renderItem={(item) => (
            <List.Item>
              <Card
                className={Styles.card_support}
                bordered={false}
                headStyle={{ borderBottom: "none" }}
                hoverable
                title={<span className="text--white">{item.title}</span>}
                onClick={item.onClick}
                bodyStyle={{ display: "none" }}
                extra={item.icon}
              ></Card>
            </List.Item>
          )}
        />
      )}
      <List
        size="large"
        grid={{ gutter: 20, column: 1 }}
        dataSource={options}
        renderItem={(item) => (
          <List.Item>
            <Card
              className={Styles.card_support}
              bordered={false}
              headStyle={{ borderBottom: "none" }}
              hoverable
              title={<span className="text--white">{item.title}</span>}
              onClick={item.onClick}
              bodyStyle={{ display: "none" }}
              extra={item.icon}
            ></Card>
          </List.Item>
        )}
      />
    </>
  );
};
