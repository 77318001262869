import React from "react";
import { Button, Carousel, Grid, Typography } from "antd";
import Styles from "./HomeComponents.module.css";
import { products } from "../../../utils/data";
const { Title, Paragraph } = Typography;

export const CarouselHome = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <div className={Styles.container}>
      <Typography className={Styles.typography_title}>
        <Title level={1} className={Styles.title}>
          Welcome to Support
        </Title>
        <Paragraph className="text--white">
          Already a customer?, contact us for support
        </Paragraph>
      </Typography>
      <div className={Styles.content_carousel}>
        <Carousel autoplay autoplaySpeed={20000}>
          {products.map((product, index) => (
            <div className={Styles.carousel} key={index}>
              <Typography>
                <Title level={2} className="text--white">
                  {product.title}
                </Title>
                <Paragraph
                  className={Styles.carousel_description}
                >
                  {product.description}
                </Paragraph>
              </Typography>
            </div>
          ))}
        </Carousel>
        <div className={Styles.actions}>
          <Button
            className={Styles.actions__button}
            style={{
              width: xs ? "80%" : "35%",
            }}
            type="text"
            onClick={() => window.open("https://quattroapps.com/contact/")}
          >
            Any ideas for a new App?
          </Button>
          <Button
            style={{ width: xs ? "80%" : "30%" }}
            onClick={() => window.open("https://quattroapps.com/")}
          >
            Go to our website
          </Button>
        </div>
      </div>
    </div>
  );
};
