import React, { useContext, useEffect } from "react";
import { Col, Row } from "antd";
import { LoginForm, ResetPasswordModal } from "../../components/pages";
import AuthContext from "../../context/AuthContext";
import { CustomModal } from "../../components/ui";

import { useSearchParams } from "react-router-dom";

export const Homepage = () => {
  const {
    isModalRecoverOpen,
    onOpenCloseModalRecover,
    validateExternalSession,
  } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let existingToken = localStorage.getItem("info");
    if (!existingToken)
      if (searchParams.get("token")) {
        const token = searchParams.get("token");
        const session = searchParams.get("session");
        if (token) {
          validateExternalSession(token, session);
        }
      }
  }, []);
  return (
    <>
      <Row justify="center" align="middle">
        <Col lg={18} md={24}>
          <LoginForm />
        </Col>
      </Row>
      <ResetPasswordModal />
    </>
  );
};
