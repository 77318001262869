import { ReactNode } from "react";

export const AppContent = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div
        style={{
          padding: "0 5vw 0 0",
          minHeight: "74vh",
        }}
      >
        {children}
      </div>
    </>
  );
};
