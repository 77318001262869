import React, { useContext, useState } from "react";
import { Button, Form, Input, Row } from "antd";
import { ColComponent } from "../../ui/ColComponent";
import TextArea from "antd/es/input/TextArea";
import { Typography } from "antd";
import DataContext from "../../../context/DataContext";
import AuthContext from "../../../context/AuthContext";
import { UploadInput } from "../../ui";
import { FormTicketProps } from "../../../types/interfaces";
import Styles from "./SupportForm.module.css";

const { Title, Paragraph } = Typography;

const FORM_INITIAL_STATE: FormTicketProps = {
  subject: "",
  details: "",
  evidence: null,
};

export const SupportForm = () => {
  const [form] = Form.useForm();
  const [values, setValues] = useState(FORM_INITIAL_STATE);
  const { onCreateNewTicket, isLoading } = useContext(DataContext);
  const { userData, productSelected } = useContext(AuthContext);

  return (
    <Row gutter={[0, 0]} justify="center" align="middle">
      <ColComponent xl={24}>
        <Typography>
          <Title className={Styles.support_form_title} level={4}>Submit a new ticket</Title>
          {productSelected?.product?.name && (
            <Paragraph style={{ textAlign: "right" }}>
              Application: <strong> {productSelected?.product?.name}</strong>
            </Paragraph>
          )}
          <Paragraph>
            We are here to help!, please create a ticket and we will reach out
            to you as soon as possible
          </Paragraph>
        </Typography>
        <Form
          onFinish={() => {
            onCreateNewTicket(values, userData.id, () => {
              form.setFieldsValue(FORM_INITIAL_STATE);
              setValues(FORM_INITIAL_STATE);
            });
          }}
          layout="vertical"
          form={form}
          initialValues={values}
        >
          <Form.Item
            label="Subject"
            name="subject"
            className={Styles.support_form_item}
            rules={[
              {
                required: true,
                message: "Please input a subject",
              },
            ]}
          >
            <Input
              placeholder="Subject"
              name="subject"
              value={values.subject}
              onChange={(event) =>
                setValues((prev) => {
                  return { ...prev, subject: event.target.value };
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Details"
            name="details"
            className={Styles.support_form_item}
            rules={[
              {
                required: true,
                message: "Please input more details",
              },
            ]}
          >
            <TextArea
              rows={4}
              onChange={(event) =>
                setValues((prev) => {
                  return { ...prev, details: event.target.value };
                })
              }
              placeholder="Details"
              name="details"
              value={values.details}
            />
          </Form.Item>
          <Form.Item label="Attach file or image" name="evidence" className={Styles.support_form_item}>
            <UploadInput
              value={values.evidence}
              onChange={(file) =>
                setValues((prev) => {
                  return {
                    ...prev,
                    evidence: file,
                  };
                })
              }
            />
          </Form.Item>

          <Form.Item className={Styles.support_form_item}>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </ColComponent>
    </Row>
  );
};
