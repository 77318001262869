import React from "react";
import {
  TokenProps,
  TicketProps,
  ClientProductProps,
  FormTicketProps,
  FormCommentProps,
} from "../types/interfaces";
import {
  ALERT_INITIAL_STATE,
  CLIENT_PRODUCT_INITIAL_STATE,
} from "../utils/data";

interface Props {
  isLoading: boolean;
  isSuccess: { status: boolean; message: string };
  isError: { status: boolean; message: string };
  isTicketModalOpen: boolean;
  isCommentModalOpen: boolean;
  isModalSupportLogin: boolean;
  ticketList: TicketProps[];
  productInfo: ClientProductProps;
  handleEditTicket: ({
    ticketId,
    updates,
    next,
  }: {
    ticketId: string;
    updates: any;
    next?: () => void;
  }) => void;
  onCreateNewTicket: (
    values: FormTicketProps,
    clientId: string,
    next?: () => void
  ) => void;
  onCreateComment: (
    values: FormCommentProps,
    client: TokenProps,
    next?: () => void
  ) => void;
  OnOpenCloseTicketModal: (value: boolean) => void;
  OnOpenCloseCommentModal: (value: boolean) => void;
  onOpenModalLoginSupport: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTicketsByClientProductId: (idClientProduct: string) => void;
  fetchUserGuide: (id: string) => void;
  goToClientsApp: () => void;
  handleFetchAwsFile: (path: string) => void;
  fetchDashboardProduct: () => void;
}

const DataContext = React.createContext<Props>({
  isLoading: false,
  isSuccess: ALERT_INITIAL_STATE,
  isError: ALERT_INITIAL_STATE,
  isTicketModalOpen: false,
  isCommentModalOpen: false,
  isModalSupportLogin: false,
  ticketList: [],
  productInfo: CLIENT_PRODUCT_INITIAL_STATE,
  handleEditTicket: () => {},
  onCreateNewTicket: () => {},
  onCreateComment: () => {},
  OnOpenCloseTicketModal: () => {},
  OnOpenCloseCommentModal: () => {},
  onOpenModalLoginSupport: () => {},
  fetchTicketsByClientProductId: () => {},
  fetchUserGuide: () => {},
  goToClientsApp: () => {},
  handleFetchAwsFile: () => {},
  fetchDashboardProduct: () => {},
});

export default DataContext;
