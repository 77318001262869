import React, { useContext } from "react";
import { Col, Row } from "antd";
import {
  InstructionsList,
  SupportCardList,
  SupportForm,
} from "../../components/pages";
import { CustomModal } from "../../components/ui";
import DataContext from "../../context/DataContext";
import Styles from "./SupportPage.module.css";

export const SupportPage = () => {
  const { isTicketModalOpen, OnOpenCloseTicketModal } = useContext(DataContext);

  return (
    <div className={Styles.content}>
      <Row className={Styles.content__row} gutter={[16, 0]} align="middle">
        <Col xs={24} md={18} className={Styles.col__instruction}>
          <InstructionsList />
        </Col>
        <Col xs={24} md={18} lg={6} className={Styles.col__support}>
          <SupportCardList />
        </Col>
      </Row>
      <CustomModal
        isModalOpen={isTicketModalOpen}
        handleCancel={() => OnOpenCloseTicketModal(false)}
      >
        <SupportForm />
      </CustomModal>
    </div>
  );
};
