import React, { useEffect, useState } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, message, Tooltip, Upload } from "antd";

interface Props {
  onChange?: (file: any) => void;
  value: any;
}

export const UploadInput = ({ onChange, value }: Props) => {
  // const handleUpload = () => {
  //   if (!file) {
  //     message.error("Please select a file to upload");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   fetch("http://your-node-server-url/upload", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         message.success("File uploaded successfully");
  //       } else {
  //         message.error("File upload failed");
  //       }
  //     })
  //     .catch((error) => {
  //       message.error("File upload failed");
  //       console.error("Error:", error);
  //     });
  // };

  return (
    <>
      <div
        style={{
          borderRadius: "5px",
          padding: "5px",
          marginBottom: "5px",
          border: "1px solid gray",
          maxHeight: "30px",
          height: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div style={{ width: "95%", overflow: "hidden" }}>
          <a href="#">{value && value.name}</a>
        </div>
        <Tooltip title="Remove File">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => onChange && onChange(null)}
          ></Button>
        </Tooltip>
      </div>

      <div>
        <Upload
          name="file"
          accept=".pdf,.doc,.docx, .png"
          showUploadList={false} // Hide the file list
          customRequest={() => {}} // Disable default upload behavior
          onChange={(info) => {
            onChange && onChange(info.file.originFileObj);
          }}
        >
          <Button type="link" icon={<UploadOutlined />}>
            Select File
          </Button>
        </Upload>
      </div>
    </>
  );
};
