import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Select } from "antd";
import { Header } from "antd/es/layout/layout";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/ui";
import { MenuOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib";

interface ListProps {
  label: string;
  value: string;
}

const LIST_INITIAL_STATE: ListProps[] = [{ label: "", value: "" }];

export const AppHeader = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<ListProps[]>(LIST_INITIAL_STATE);
  const {
    productList,
    productSelected,
    handleChangeProduct,
    onLogout,
    openCloseModalPassword,
  } = useContext(AuthContext);

  useEffect(() => {
    if (productList?.length > 0) {
      const itemProducts: ListProps[] = productList.map(({ product }) => {
        return {
          label: product.name,
          value: product.id,
        };
      });
      setProducts(itemProducts);
    }
  }, [productList]);

  const items: MenuProps["items"] = [
    {
      label: "Change Password",
      key: "change-password",
      onClick: () => openCloseModalPassword(true),
    },
    {
      label: "Logout",
      key: "logout",
      onClick: () => onLogout(),
    },
  ];

  return (
    <Header
      style={{
        padding: 20,
        background: "#FFF",
        minHeight: "18vh",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Logo onClick={() => navigate("/support")} />
      <div>
        <Select
          placeholder="Your Apps"
          value={productSelected?.product?.id}
          onChange={handleChangeProduct}
          options={products}
          style={{ width: "230px" }}
          size="large"
          disabled={products.length === 1}
        />
      </div>
      <div>
        <Dropdown menu={{ items }}>
          <Button
            type="default"
            style={{ marginRight: "1rem" }}
            icon={<MenuOutlined />}
          ></Button>
        </Dropdown>
      </div>
    </Header>
  );
};
