import React, { useContext } from "react";
import { Button, Col, Form, Input, Row, Typography, Card } from "antd";
import AuthContext from "../../../context/AuthContext";
import Styles from "./Login.module.css";

const { Title } = Typography;

export const LoginForm = () => {
  const {
    username,
    password,
    onChangeUsername,
    onChangePassword,
    handleLogin,
    openCloseModalResetPassword,
  } = useContext(AuthContext);

  return (
    <Card>
      <Row
        gutter={[24, 0]}
        justify="center"
        align="middle"
        className={Styles.login_form__row}
      >
        <Col xl={20}>
          <Typography>
            <Title level={3}>Login to Support System</Title>
          </Typography>
          <Form layout="vertical" onFinish={handleLogin}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Client ID",
                },
              ]}
            >
              <Input
                placeholder="Username"
                name="username"
                value={username}
                onChange={(event) => onChangeUsername(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={(event) => onChangePassword(event.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={Styles.login_form__signin}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Form>
          <Typography>
            <Button
              type="link"
              onClick={() => openCloseModalResetPassword(true)}
            >
              Forgot Password?
            </Button>
          </Typography>
        </Col>
      </Row>
    </Card>
  );
};
