import { Modal } from "antd";
import React, { ReactNode } from "react";

interface Props {
  title?: string;
  children: ReactNode;
  isModalOpen: boolean;
  footer?: ReactNode[] | null;
  showButtons?: boolean;
  width?: string | number;
  handleOk?: () => void;
  handleCancel?: () => void;
}
export const CustomModal = ({
  title,
  children,
  isModalOpen,
  footer,
  showButtons = false,
  width,
  handleOk,
  handleCancel,
}: Props) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
      okButtonProps={{ className: !showButtons ? "no-display" : "" }}
      cancelButtonProps={{ className: !showButtons ? "no-display" : "" }}
      centered
      width={width}
    >
      {children}
    </Modal>
  );
};
