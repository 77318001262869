import React, { useContext } from "react";
import { Row } from "antd";
import { ColComponent } from "../../components/ui/ColComponent";
import DataContext from "../../context/DataContext";

export const Dashboard = () => {
  const { isLoading } = useContext(DataContext);

  return (
    <>
      <Row gutter={[12, 12]}>
        <ColComponent md={24} lg={24} xl={24}>
          {!isLoading ? (
            <>
              Here it goes the dashboard of all clients live for admin purposes
            </>
          ) : (
            <div></div>
          )}
        </ColComponent>
      </Row>
    </>
  );
};
