import { Row, Skeleton } from "antd";
import React, { useContext, useEffect } from "react";
import { ColComponent } from "../../../components/ui/ColComponent";
import DataContext from "../../../context/DataContext";
import { useParams } from "react-router-dom";
import {
  CommentForm,
  SupportForm,
  TicketList,
} from "../../../components/pages";
import { CustomModal } from "../../../components/ui";
import Styles from "./TicketPage.module.css";

export const TicketPage = () => {
  const {
    isLoading,
    fetchTicketsByClientProductId,
    isTicketModalOpen,
    isCommentModalOpen,
    OnOpenCloseCommentModal,
    OnOpenCloseTicketModal,
    productInfo,
  } = useContext(DataContext);

  useEffect(() => {
    if (productInfo.id) {
      fetchTicketsByClientProductId(productInfo.id);
    }
  }, [productInfo]);

  return (
    <div className={Styles.ticket_page}>
      <div className={Styles.ticket_page__content}>
        <Row gutter={[12, 12]}>
          <ColComponent md={24} lg={24} xl={24}>
            <TicketList />
          </ColComponent>
        </Row>
        <CustomModal
          isModalOpen={isCommentModalOpen}
          handleCancel={() => OnOpenCloseCommentModal(false)}
        >
          <CommentForm />
        </CustomModal>
        <CustomModal
          isModalOpen={isTicketModalOpen}
          handleCancel={() => OnOpenCloseTicketModal(false)}
        >
          <SupportForm />
        </CustomModal>
      </div>
    </div>
  );
};
