import React, { useContext, useEffect } from "react";
import { useState } from "react";
import DataContext from "./DataContext";
import {
  TokenProps,
  TicketProps,
  ClientProductProps,
  FormTicketProps,
  FormCommentProps,
} from "../types/interfaces";
import { useRequest } from "../hooks";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  ALERT_INITIAL_STATE,
  CLIENT_PRODUCT_INITIAL_STATE,
  TICKET_LIST_INITIAL_STATE,
} from "../utils/data";
import AuthContext from "./AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const DataProvider = ({ children }: Props) => {
  const { productSelected, userData } = useContext(AuthContext);
  const { handleRequest, handleUpload, handleDownload } = useRequest();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);
  const [isModalSupportLogin, setIsModalSupportLogin] = useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [ticketList, setTicketList] = useState<TicketProps[]>(
    TICKET_LIST_INITIAL_STATE
  );
  const [productInfo, setProductInfo] = useState<ClientProductProps>(
    CLIENT_PRODUCT_INITIAL_STATE
  );

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isSuccess, isError]);

  useEffect(() => {
    if (productSelected) {
      const idUser = userData.id;
      fetchClientProductInfo(productSelected.id);
      if (location.pathname === `/support/ticket/${idUser}`) {
        fetchTicketsByClientProductId(productInfo.id);
      }
    }
  }, [productSelected]);

  //utils

  const createPdfFile = (data: any) => {
    try {
      const pdfArray = new Uint8Array(Object.values(data));
      const pdfBlob = new Blob([pdfArray], { type: "application/pdf" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(pdfBlob);
      downloadLink.download = "user_guide.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setIsSuccess({ status: true, message: "File Downloaded" });
    } catch (e) {
      //  setIsError({ status: true, message: "Please try again" });
    }
  };

  const fetchClientProductInfo = (idClientProduct: string) => {
    if (idClientProduct) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
        headers: {
          responseType: "buffer",
        },
      };
      handleRequest<any>({
        endpoint: `client-product/${idClientProduct}`,
        options,
        onSuccess: (response) => {
          setProductInfo(response.data);
          setIsLoading(false);
          setIsSuccess({ status: true, message: "Updated" });
        },
        onError: () => {
          setIsError({ status: true, message: "Please try again" });
          setIsLoading(false);
        },
      });
    }
  };

  const fetchUserGuide = (client_id: string) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
      headers: {
        responseType: "buffer",
      },
    };
    handleRequest<any>({
      endpoint: `guide/${client_id}`,
      options,
      onSuccess: (response) => {
        createPdfFile(response.data);
        setIsLoading(false);
      },
      onError: () => {
        setIsError({ status: true, message: "Please try again" });
        setIsLoading(false);
      },
    });
  };

  const fetchTicketsByClientProductId = (id: string) => {
    if (id) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      handleRequest<any>({
        endpoint: `ticket/${id}`,
        options,
        onSuccess: (response) => {
          setTicketList(response.data);
          setIsLoading(false);
          setIsSuccess({ status: true, message: "Updated" });
        },
        onError: () => {
          setIsError({ status: true, message: "Please try again" });
          setIsLoading(false);
        },
      });
    }
  };

  const fetchDashboardProduct = () => {
    if (productSelected && productSelected.product?.id) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      handleRequest<any>({
        endpoint: `dashboard/${productSelected.product.id}`,
        options,
        onSuccess: (response) => {
          if (response.data && response.data.dashboard) {
            typeof response.data.dashboard === "string" &&
              window.open(response.data.dashboard);
          } else {
            setIsError({ status: true, message: "Please try again" });
          }
        },
        onError: () => {
          setIsError({ status: true, message: "Please try again" });
          setIsLoading(false);
        },
      });
    } else {
      setIsError({ status: true, message: "Please try again" });
    }
  };

  const handleCreateNewTicket = async (
    values: FormTicketProps,
    userId: string,
    next?: () => void
  ) => {
    setIsLoading(true);
    const { subject, details, evidence } = values;
    let options: RequestInit = {
      method: "POST",
    };
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("details", details);
    formData.append("client_product_id", productSelected.id);
    formData.append("product_name", productSelected.product.name);
    formData.append("user_id", userId);
    if (evidence) {
      formData.append("file", evidence, evidence.name);
    }
    options.body = formData;
    handleUpload({
      endpoint: "ticket",
      options,
      onSuccess: () => {
        setIsTicketModalOpen(false);
        next && next();
        if (location.pathname === "/support") {
          navigate(`/support/ticket/${userId}`);
        } else {
          fetchTicketsByClientProductId(productInfo.id);
        }
        setIsSuccess({ status: true, message: "Ticket Created" });
        setIsLoading(false);
      },
      onError: () => {
        //  setIsError({ status: true, message: "Please try again" });
        setIsLoading(false);
      },
    });
  };

  const handleCreateNewComment = (
    values: FormCommentProps,
    user: TokenProps,
    next?: () => void
  ) => {
    const ticketId = localStorage.getItem("ticket");
    const { comment, evidence } = values;
    if (ticketId) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
      };
      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("ticket_id", ticketId);
      formData.append("user_id", user.id);
      formData.append("product_name", productSelected.product.name);
      if (evidence) {
        formData.append("file", evidence, evidence.name);
      }
      options.body = formData;
      handleUpload({
        endpoint: "comment",
        options,
        onSuccess: () => {
          setIsSuccess({ status: true, message: "Comment Created" });
          setIsLoading(false);
          setIsCommentModalOpen(false);
          fetchTicketsByClientProductId(productInfo.id);
          setIsLoading(false);
          next && next();
        },
        onError: () => {
          setIsCommentModalOpen(false);
          //  setIsError({ status: true, message: "Please try again" });
          setIsLoading(false);
        },
      });
      localStorage.removeItem("ticket");
    } else {
      setIsCommentModalOpen(false);
      //  setIsError({ status: true, message: "Please try again" });
      setIsLoading(false);
    }
  };
  const handleEditTicket = ({
    ticketId,
    updates,
    next,
  }: {
    ticketId: string;
    updates: any;
    next?: () => void;
  }) => {
    if (ticketId) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "PUT",
        body: JSON.stringify({ ...updates }),
      };
      handleRequest({
        endpoint: `ticket/${ticketId}`,
        options,
        onSuccess: () => {
          setIsSuccess({ status: true, message: "Updated" });
          setIsLoading(false);
          fetchTicketsByClientProductId(productInfo.id);
          next && next();
        },
        onError: () => {
          setIsError({ status: true, message: "Please try again" });
          setIsLoading(false);
        },
      });
    } else {
      setIsError({ status: true, message: "Please try again" });
      setIsLoading(false);
    }
  };

  const goToClientsApp = () => {
    const token = localStorage.getItem("info");
    if (token) {
      const domain = productInfo?.product?.host;
      window.open(`${domain}?token=${token}`);
    }
  };

  const handleFetchAwsFile = (path: string) => {
    if (path) {
      let encodedPath = encodeURIComponent(path);
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
        headers: {
          responseType: "arraybuffer",
        },
      };
      handleDownload({
        endpoint: `file/?path=${encodedPath}`,
        options,
        onSuccess: async (response: any) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = path.split("/").pop() as string; // Use the file name from the path
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          setIsLoading(false);
        },
        onError: () => {
          setIsError({ status: true, message: "Please try again" });
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <DataContext.Provider
      value={{
        isLoading,
        isSuccess,
        isError,
        isTicketModalOpen,
        isCommentModalOpen,
        isModalSupportLogin,
        ticketList,
        productInfo,
        handleEditTicket,
        OnOpenCloseTicketModal: (value: boolean) => setIsTicketModalOpen(value),
        OnOpenCloseCommentModal: (value: boolean) =>
          setIsCommentModalOpen(value),
        onCreateNewTicket: handleCreateNewTicket,
        onCreateComment: handleCreateNewComment,
        onOpenModalLoginSupport: () => setIsModalSupportLogin((prev) => !prev),
        fetchTicketsByClientProductId,
        fetchUserGuide,
        goToClientsApp,
        handleFetchAwsFile,
        fetchDashboardProduct,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
