import { Typography } from "antd";
import Styles from "./InstructionsList.module.css";
const { Title, Paragraph, Link } = Typography;

export const InstructionsList = () => {
  return (
    <div className={Styles.instructions_list}>
      <Typography className="text--white">
        <Title className="text--white">Welcome to Support</Title>
        <Paragraph className="text--white">
          Try these simple troubleshooting steps first before creating a service
          ticket:
        </Paragraph>
        <ol>
          <li>Make sure your internet connection is solid</li>
          <li>
            Remove the cache from the browser to get the latest version of your
            website
          </li>
          <li>
            Take a screenshot of what your app is doing or any error messages
            you are receiving.
          </li>
          <li>
            If the issue is still there, use the Submit a new ticket to the
            right
          </li>
          <li>
            After submitting a new ticket, you will receive notification of
            receipt to the email registered to the Client ID
          </li>
          <li>
            You can also send an email, however, submitted tickets receive first
            priority
          </li>
        </ol>

        <Paragraph className="text--white">
          We are here for you at work hours:{" "}
          <Link className="text--white" href="tel:+1432-363-4009">
            432-363-4009
          </Link>
        </Paragraph>
        <Paragraph className="text--white">
          You can send us an email{" "}
          <Link href="mailto:info@quattroapps.com" className="text--white">
            info@quattroapps.com
          </Link>
          , but we highly recommend to get a ticket before sending an email.
        </Paragraph>
      </Typography>
    </div>
  );
};
