import React from "react";
import { ClientProductProps, TokenProps } from "../types/interfaces";
import {
  ALERT_INITIAL_STATE,
  CLIENT_PRODUCT_INITIAL_STATE,
  PRODUCT_INITIAL_STATE,
  TOKEN_INITIAL_STATE,
} from "../utils/data";

interface Props {
  isLoggedIn: boolean;
  username: string;
  onChangeUsername: any;
  password: string;
  onChangePassword: any;
  isSuccess: { status: boolean; message: string };
  isError: { status: boolean; message: string };
  isLoading: boolean;
  isAdmin: boolean;
  userData: TokenProps;
  isModalRecoverOpen: boolean;
  productList: ClientProductProps[];
  productSelected: ClientProductProps;
  isModalChangePasswordVisible: boolean;
  isModalResetPasswordVisible: boolean;
  onOpenCloseModalRecover: (value: boolean) => void;
  openCloseModalResetPassword: (value: boolean) => void;
  openCloseModalPassword: (value: boolean) => void;
  handleChangePassword: ({
    data,
    next,
  }: {
    data: {
      currentPassword: string;
      newPassword: string;
    };
    next: () => void;
  }) => void;
  handleSendResetPassword: ({
    username,
    next,
  }: {
    username: string;
    next: () => void;
  }) => void;
  handleLogin: () => void;
  onLogout: () => void;
  validateToken: () => boolean;
  recoverClient: (email: string) => void;
  handleChangeProduct: (idProduct: string) => void;
  validateExternalSession: (token: string, session: string | null) => void;
}

const AuthContext = React.createContext<Props>({
  isLoggedIn: false,
  username: "",
  password: "",
  isSuccess: ALERT_INITIAL_STATE,
  isError: ALERT_INITIAL_STATE,
  isLoading: false,
  isAdmin: false,
  userData: TOKEN_INITIAL_STATE,
  isModalRecoverOpen: false,
  productList: [],
  productSelected: CLIENT_PRODUCT_INITIAL_STATE,
  isModalChangePasswordVisible: false,
  isModalResetPasswordVisible: false,
  onOpenCloseModalRecover: () => {},
  openCloseModalPassword: () => {},
  openCloseModalResetPassword: () => {},
  handleSendResetPassword: () => {},
  handleChangePassword: () => {},
  onChangeUsername: () => {},
  onChangePassword: () => {},
  handleLogin: () => {},
  onLogout: () => {},
  validateToken: () => false,
  recoverClient: () => {},
  handleChangeProduct: () => {},
  validateExternalSession: () => {},
});

export default AuthContext;
