import React, { CSSProperties, useContext } from "react";
import { Badge, Button, Descriptions, Empty, Tooltip } from "antd";
import type { DescriptionsProps } from "antd";
import { CalendarComponent } from "../../components/calendar";
import dayjs, { Dayjs } from "dayjs";
import DataContext from "../../context/DataContext";
import Title from "antd/es/typography/Title";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Styles from "./ClientPage.module.css";
const getDiff = (date: Dayjs) => {
  const today = dayjs();
  return today.diff(date, "day");
};

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const labelStyles: CSSProperties = {
  backgroundColor: "#17549a",
  color: "#FFF",
  fontSize: "1rem",
};
export const ClientPage: React.FC = () => {
  const navigate = useNavigate();
  const { productInfo } = useContext(DataContext);
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: <strong>Product</strong>,
      labelStyle: labelStyles,
      children: productInfo?.product?.name,
    },
    {
      key: "2",
      label: <strong>Billing Mode</strong>,
      labelStyle: labelStyles,
      children: productInfo?.billing_mode,
    },
    {
      key: "3",
      label: <strong>Automatic Renewal</strong>,
      labelStyle: labelStyles,
      children: productInfo?.auto_renewal ? "YES" : "NO",
    },
    {
      key: "4",
      label: <strong>Release Date</strong>,
      labelStyle: labelStyles,
      children: (
        <>
          {productInfo.release_date &&
            dayjs(productInfo.release_date).format("MM/DD/YYYY")}
        </>
      ),
    },
    {
      key: "5",
      label: <strong>Usage Time</strong>,
      labelStyle: labelStyles,
      children: <>{getDiff(dayjs(productInfo.release_date))} days</>,
    },
    {
      key: "6",
      label: <strong>Status</strong>,
      labelStyle: labelStyles,
      children: (
        <Badge
          status={productInfo?.status ? "success" : "error"}
          text={productInfo?.status ? "Running" : "Stopped"}
        />
      ),
      span: 3,
    },
    {
      key: "7",
      label: <strong>Monthly Rate</strong>,
      labelStyle: labelStyles,
      children:
        productInfo?.monthly_payment &&
        USDollar.format(parseFloat(productInfo?.monthly_payment)),
    },
    {
      key: "8",
      label: <strong>Yearly Rate</strong>,
      labelStyle: labelStyles,
      children:
        productInfo?.yearly_payment &&
        USDollar.format(parseFloat(productInfo?.yearly_payment)),
    },
    {
      key: "9",
      label: <strong>Pay Date</strong>,
      labelStyle: labelStyles,
      children: (
        <>
          {productInfo?.pay_date &&
            dayjs(productInfo.pay_date).format("DD/MMMM")}
        </>
      ),
    },
    {
      key: "10",
      label: <strong>Events</strong>,
      labelStyle: labelStyles,
      children: (
        <>
          {productInfo.schedule && productInfo.schedule?.length > 0 ? (
            <CalendarComponent schedule={productInfo.schedule} />
          ) : (
            <Empty />
          )}
        </>
      ),
    },
  ];
  return (
    <div className={Styles.client_page}>
      <Descriptions
        title={
          <>
            <Tooltip title="Home" placement="right">
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate("/support")}
              ></Button>
            </Tooltip>
            <Title level={2} className={Styles.client_page__title}>
              Account Information
            </Title>
          </>
        }
        bordered
        contentStyle={{ fontSize: "1rem" }}
        items={items}
        className={Styles.client_page__descriptions}
      />
    </div>
  );
};
