import { Button, Col, Form, Input, Modal, Row } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import React, { useContext, useEffect } from "react";
import { Base64 } from "js-base64";
import AuthContext from "../../../context/AuthContext";

export const ChangePasswordModal = () => {
  const [form] = Form.useForm();
  const [currPassword, setCurrPassword] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false);
  const {
    isModalChangePasswordVisible,
    openCloseModalPassword,
    handleChangePassword,
  } = useContext(AuthContext);

  useEffect(() => {
    setIsReadyToSubmit(!!(currPassword && password1 && password2));
  }, [currPassword, password1, password2]);

  useEffect(() => {
    form.setFieldsValue({
      password1: "",
      password2: "",
      currPassword: "",
    });
    form.resetFields();
  }, []);

  const onFinish = () => {
    if (currPassword !== "" && password1 !== "" && password2 !== "") {
      const currentPasswordLoop1 = Base64.encode(currPassword);
      const currentPassword = Base64.encode(currentPasswordLoop1);
      const newPasswordLoop1 = Base64.encode(password1);
      const newPassword = Base64.encode(newPasswordLoop1);
      handleChangePassword({
        data: {
          currentPassword,
          newPassword,
        },
        next: () => {
          openCloseModalPassword(false);
          setCurrPassword("");
          setPassword1("");
          setPassword2("");
          form.setFieldsValue({
            password1: "",
            password2: "",
            currPassword: "",
          });
          form.resetFields();
        },
      });
    }
  };
  return (
    <Modal
      title="Change Password"
      open={isModalChangePasswordVisible}
      onCancel={() => openCloseModalPassword(false)}
      footer={null}
    >
      <Row justify="center">
        <Col span={20}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Current  password"
              name="currentPassword"
              className="collections-form__form-item"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                value={currPassword}
                autoComplete="current-password"
                placeholder="Enter current  password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onChange={(e) => setCurrPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="New password"
              className="collections-form__form-item"
              name="password1"
              rules={[
                { required: true, message: "Please input your new password" },
                {
                  min: 6,
                  message: "Password must be at least 6 characters long!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("currentPassword") === value) {
                      return Promise.reject(
                        new Error(
                          "The new password must be different from the current password!"
                        )
                      );
                    }
                  },
                }),
              ]}
            >
              <Input.Password
                value={password1}
                autoComplete="new-password"
                placeholder="New password"
                onChange={(e) => setPassword1(e.target.value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="password2"
              label="Repeat new password"
              className="collections-form__form-item"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Repeat new password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                autoComplete="new-password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  type="default"
                  onClick={() => openCloseModalPassword(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={onFinish}
                  disabled={!isReadyToSubmit}
                  style={{ marginLeft: "20px" }}
                >
                  Update
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
