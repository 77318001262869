import React from "react";
import desktopLogo from "../../assets/images/company_logo.png";
import mobileLogo from "../../assets/images/company_logo_mobile.png";
import { Grid } from "antd";

export const Logo = ({
  mobile,
  onClick,
}: {
  mobile?: boolean;
  onClick?: () => void;
}) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  if (xs || mobile) {
    return (
      <img
        src={mobileLogo}
        alt="Company Logo"
        className="logo-img-mobile"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
    );
  } else {
    return (
      <img
        src={desktopLogo}
        alt="Company Logo"
        className="logo-img"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
    );
  }
};
