import React, { useContext } from "react";
import { Button, Dropdown, MenuProps, Space, Typography } from "antd";
import logo from "../../assets/images/company_logo.png";
import { Header } from "antd/es/layout/layout";
import { PhoneOutlined, MailOutlined, DownOutlined } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { Logo } from "../../components/ui";
const { Link } = Typography;

const items: MenuProps["items"] = [
  {
    label: <Link href="tel:+1432-363-4009">Call us</Link>,
    key: "1",
    icon: <PhoneOutlined className="icon" />,
  },
  {
    label: <a href="mailto:info@quattroapps.com">Email us</a>,
    key: "2",
    icon: <MailOutlined className="icon" />,
  },
];

export const PublicAppHeader = () => {
  return (
    <Header
      style={{
        padding: 15,
        background: "#FFF",
        minHeight: "18vh",
        marginBottom: "18px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Logo />
      <div>
        <Dropdown menu={{ items }} trigger={["hover"]}>
          <Button icon={<PhoneOutlined />}>Contact us</Button>
        </Dropdown>
      </div>
    </Header>
  );
};
