import React, { useContext } from "react";
import DataContext from "../../context/DataContext";
import { Button, Result, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Styles from "./UserGuide.module.css";

export const UserGuidePage = () => {
  const navigate = useNavigate();
  const { productInfo } = useContext(DataContext);
  return (
    <div className={Styles.user_guide}>
      <div
        className={Styles.user_guide__content}
      >
        <Tooltip title="Home" placement="right">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/support")}
          ></Button>
        </Tooltip>
        {productInfo?.product?.guide ? (
          <iframe
            src={productInfo?.product?.guide}
            width="100%"
            height="600"
            allowFullScreen
            style={{ margin: "10px" }}
          ></iframe>
        ) : (
          <Result
            status="warning"
            title="Your app does not have any user guide yet"
            extra={
              <Button type="primary" onClick={() => navigate("/support")}>
                Back to Support
              </Button>
            }
          />
        )}
      </div>
    </div>
  );
};
