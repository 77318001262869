import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Row } from "antd";
import { ColComponent } from "../../ui/ColComponent";
import TextArea from "antd/es/input/TextArea";
import { Typography } from "antd";
import DataContext from "../../../context/DataContext";
import AuthContext from "../../../context/AuthContext";
import { UploadInput } from "../../ui";
import { FormCommentProps } from "../../../types/interfaces";

const { Title } = Typography;

const FORM_INITIAL_STATE: FormCommentProps = { comment: "", evidence: null };

export const CommentForm = () => {
  const [form] = Form.useForm();
  const [values, setValues] = useState(FORM_INITIAL_STATE);
  const { onCreateComment, isLoading } = useContext(DataContext);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    setValues(FORM_INITIAL_STATE);
    form.setFieldsValue(FORM_INITIAL_STATE);
  }, [form]);

  return (
    <Row gutter={[0, 0]} justify="center" align="middle">
      <ColComponent xl={24}>
        <Typography>
          <Title level={4}>Create a new comment:</Title>
        </Typography>
        <Form
          onFinish={() => {
            onCreateComment(values, userData, () => {
              setValues(FORM_INITIAL_STATE);
              form.setFieldsValue(FORM_INITIAL_STATE);
            });
          }}
          form={form}
          layout="vertical"
          initialValues={values}
        >
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: "Please input your new comment",
              },
            ]}
          >
            <TextArea
              rows={6}
              onChange={(event) =>
                setValues((prev) => {
                  return {
                    ...prev,
                    comment: event.target.value,
                  };
                })
              }
              placeholder="Detail"
              name="comment"
              value={values.comment}
            />
          </Form.Item>
          <Form.Item label="Attach file or image" name="evidence">
            <UploadInput
              value={values.evidence}
              onChange={(file) =>
                setValues((prev) => {
                  return {
                    ...prev,
                    evidence: file,
                  };
                })
              }
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </ColComponent>
    </Row>
  );
};
