import React from "react";
import type { Dayjs } from "dayjs";
import type { BadgeProps, CalendarProps } from "antd";
import { Badge, Calendar } from "antd";
import { ScheduleItemProps } from "../../types/interfaces";
import dayjs from "dayjs";

// const getListData = (value: Dayjs) => {
//   let listData;
//   switch (value.date()) {
//     case 8:
//       listData = [{ type: "warning", content: "This is warning" }];
//       break;
//     default:
//   }
//   return listData || [];
// };

// const getMonthData = (value: Dayjs) => {
//   if (value.month() === 8) {
//     return "";
//   }
// };

export const CalendarComponent = ({
  schedule,
}: {
  schedule: ScheduleItemProps[];
}) => {
  // const monthCellRender = (value: Dayjs) => {
  //   const num = getMonthData(value);
  //   return num ? (
  //     <div className="notes-month">
  //       <section>{num}</section>
  //       <span>Backlog number</span>
  //     </div>
  //   ) : null;
  // };

  const dateCellRender = (value: Dayjs) => {
    // const listData = getListData(value);
    const listData: { type: "warning" | "default"; content: string }[] = [];
    schedule.forEach((event) => {
      if (value.isSame(dayjs(event.date), "day")) {
        listData.push({
          type: event.type === "PAYMENT" ? "warning" : "default",
          content: event.description,
        });
      }
    });
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge
              status={item.type as BadgeProps["status"]}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    // if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  return <Calendar cellRender={cellRender} />;
};
