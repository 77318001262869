import {
  AdminPage,
  Dashboard,
  Homepage,
  SupportPage,
  ClientPage,
  UserGuidePage,
  TicketPage,
} from "../../pages";

export const routes = {
  home: "/",
  support: "/support",
  ticket: "/support/ticket/:idClient",
  client: "/client/:idClient",
  dashboard: "/dashboard",
  admin: "/adm",
  userguide: "/guide/:idProduct",
};

export const Pages = [
  { path: routes.home, protected: false, component: <Homepage /> },
  { path: routes.support, protected: true, component: <SupportPage /> },
  { path: routes.ticket, protected: true, component: <TicketPage /> },
  { path: routes.client, protected: true, component: <ClientPage /> },
  { path: routes.admin, protected: true, component: <AdminPage /> },
  { path: routes.dashboard, protected: true, component: <Dashboard /> },
  { path: routes.userguide, protected: true, component: <UserGuidePage /> },
];
