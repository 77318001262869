import React, { ReactNode, useContext, useEffect, useRef } from "react";
import { Layout, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { AppHeader } from "../components/AppHeader";
import { AppContent } from "../components/AppContent";
import { AppFooter } from "../components";
import DataContext from "../../context/DataContext";
import { ChangePasswordModal } from "../../components/pages";
import AuthContext from "../../context/AuthContext";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { isSuccess, isError } = useContext(DataContext);
  const { isSuccess: authSuccess, isError: authError } =
    useContext(AuthContext);
  const hasSucceded = useRef(false);
  const hasFailed = useRef(false);

  useEffect(() => {
    if (isSuccess.status && !hasSucceded.current) {
      success(isSuccess.message);
      hasSucceded.current = true;
    }
  }, [isSuccess.status]);

  useEffect(() => {
    if (isError.status && !hasFailed.current) {
      error(isError.message);
      hasFailed.current = true;
    }
  }, [isError]);

  useEffect(() => {
    if (authSuccess.status && !hasSucceded.current) {
      hasSucceded.current = true;
      success(authSuccess.message);
    }
  }, [authSuccess.status]);

  useEffect(() => {
    if (authError.status && !hasFailed.current) {
      hasFailed.current = true;
      error(authError.message);
    }
  }, [authError.status]);

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };
  return (
    <>
      <Layout>
        {contextHolder}
        <AppHeader />
        <Content>
          <AppContent>{children}</AppContent>
        </Content>
        <AppFooter />
      </Layout>
      <ChangePasswordModal />
    </>
  );
};
