import React, { useContext, useEffect, useRef } from "react";
import { Grid, Layout, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { AppFooter } from "../components";
import { PublicAppHeader } from "../components/PublicAppHeader";
import AuthContext from "../../context/AuthContext";
import Sider from "antd/es/layout/Sider";
import { CarouselHome } from "../../components/pages";
import Styles from "./PublicLayout.module.css";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicLayout = ({ children }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { isError, isSuccess } = useContext(AuthContext);
  const { useBreakpoint } = Grid;
  const { xs, sm } = useBreakpoint();
  const hasSucceded = useRef(false);
  const hasFailed = useRef(false);

  useEffect(() => {
    if (isSuccess.status && !hasSucceded.current) {
      success(isSuccess.message);
      hasSucceded.current = true;
    }
  }, [isSuccess.status]);

  useEffect(() => {
    if (isError.status && !hasFailed.current) {
      error(isError.message);
      hasFailed.current = true;
    }
  }, [isError]);

  useEffect(() => {
    if (isError.status && !hasFailed.current) {
      error(isError.message);
      hasFailed.current = true;
    }
  }, [isError]);

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };
  return (
    <Layout className={Styles.public_layout}>
      {contextHolder}
      {!xs && (
        <Sider
          width={xs ? 0 : 720}
          breakpoint="lg"
          collapsedWidth="300"
          className={Styles.public_layout_sider}
        >
          <CarouselHome />
        </Sider>
      )}

      <Layout>
        <PublicAppHeader />
        <Content>
          <Layout>
            <div className={Styles.public_layout__content__children}>
              {children}
            </div>
          </Layout>
          <AppFooter />
        </Content>
      </Layout>
    </Layout>
  );
};
