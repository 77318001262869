import dayjs from "dayjs";
import {
  ClientProductProps,
  CommentProps,
  ProductProps,
  TicketProps,
  TokenProps,
} from "../types/interfaces";

export const PRODUCT_INITIAL_STATE: ProductProps = {
  id: "",
  name: "",
  guide: "",
  host: "",
};

export const CLIENT_PRODUCT_INITIAL_STATE: ClientProductProps = {
  id: "",
  auto_renewal: false,
  billing_mode: "",
  pay_date: "",
  release_date: dayjs(),
  status: false,
  monthly_payment: "",
  yearly_payment: "",
  product: PRODUCT_INITIAL_STATE,
};

export const TOKEN_INITIAL_STATE: TokenProps = {
  id: "",
  username: "",
  name: "",
  role: {
    id: "",
    description: "",
    adm: false,
  },
  client: {
    id: "",
    name: "",
    phone_number: "",
    billing_address: "",
    email: "",
    details: [],
  },
};

export const products = [
  {
    title: "Custom Applications",
    description:
      "Have multiple software tools that don’t communicate? Doing duplicate work putting the same data in multiple systems? Looking for a faster way to complete everyday tasks? Have an idea of how your processes could be improved.Look no further. Our expert development team can build integration between your existing software tools or turn your unique ideas into functional, high-performance custom applications.",
    support: "/support?app=custom",
    href: "/services/custom-applications/",
  },
  {
    title: "Websites",
    description:
      "A company website is a 24/7 business card online. Our expert team crafts dynamic and responsive (mobile-ready) websites tailored to your needs to best communicate your business to your potential clients. From sleek designs to seamless functionality, Quattro Software brings your vision to life in beautiful, elegant, and informative web design and functionality such as forms, e-commerce and more. Explore the possibilities of captivating your branded online experience with our web development services.",
    support: "/support?app=website",
    href: "/services/website-development/",
  },
  {
    title: "QuickBooks Integration",
    description:
      "QuickBooks is the go-to accounting software solution for many businesses. Because of this, Quattro Software has focused on building tools and applications that are seamlessly extend the in-the-box functionality of QuickBooks with innovative tools that make businesses easier to run. From custom reports to functions like WIP, AR reporting, and more, Quattro Software exists to build QuickBooks integration tools and support.",
    support: "/support?app=quickbooks",
    href: "/services/quickbooks/",
  },
];

export const COMMENT_INITIAL_STATE: CommentProps[] = [
  {
    id: "0",
    user: {
      name: "",
      username: "",
    },
    client_id: "",
    comment: "",
    is_admin: false,
    date: "",
    ticket_id: "",
  },
];

export const TICKET_LIST_INITIAL_STATE: TicketProps[] = [
  {
    id: "",
    subject: "",
    date: "",
    client_id: "",
    comment_list: COMMENT_INITIAL_STATE,
    solved: false,
  },
];

export const ALERT_INITIAL_STATE = { status: false, message: "" };

export const LIST_INITIAL_STATE = [
  {
    title: "",
    onClick: () => {},
    icon: null,
  },
  {
    title: "",
    onClick: () => {},
    icon: null,
  },
  {
    title: "",
    onClick: () => {},
    icon: null,
  },
  {
    title: "",
    onClick: () => {},
    icon: null,
  },
  {
    title: "",
    onClick: () => {},
    icon: null,
  },
  {
    title: "",
    onClick: () => {},
    icon: null,
  },
];
