import { ConfigProviderProps } from "antd/lib/config-provider";

export const config: ConfigProviderProps = {
  componentSize: "large",
  theme: {
    token: {
      colorPrimary: "#17549a",
      colorLink: "#2e79ff",
      colorLinkHover: "#17549a",
      colorFillSecondary: "#f5f5f5",
      colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
    },
    components: {
      Button: {
        colorBgTextHover: "",
      },
    }
  },
};
